import React from "react";
import { TablaMovimientos } from "./TablaMovimientos";
import { IoIosClose } from "react-icons/io";

export const TablaMovimientosModal = ({
  formatDate,
  usuarioRuta,
  setMostrarMovimientos,
  ingresos,
  salidas,
}) => {
  return (
    <div className="w-full min-h-screen h-screen z-40 bg-black bg-opacity-50 px-2 md:px-8 flex justify-center items-center fixed top-0 left-0">
      <div className="bg-gray-200 w-[400px] md:w-full py-4 px-1 rounded-md relative max-h-[90%] overflow-y-auto">
        <IoIosClose
          className="absolute right-0 top-0 cursor-pointer"
          size={40}
          onClick={() => setMostrarMovimientos(false)}
        />
        <span className="font-bold text-lg ml-2">Movimientos</span>
        {usuarioRuta?.nombreRuta === "Ferch1" ||
        usuarioRuta?.nombreRuta === "Ferch2" ||
        usuarioRuta?.nombreRuta === "Ferch3" ? (
          <>
            <p className="font-semibold  text-base ml-2">
              Ingresos:{" "}
              <span className="text-green-700 font-bold">
                {ingresos ? ingresos : 0}
              </span>
            </p>
            <p className="font-semibold  text-base ml-2">
              Salidas:{" "}
              <span className="text-red-600 font-bold">
                {salidas ? salidas : 0}
              </span>
            </p>
            <p className="font-semibold  text-base ml-2">
              Caja:{" "}
              <span className="text-blue-600 font-bold">
                {ingresos - salidas}
              </span>
            </p>
          </>
        ) : null}

        <div className="w-full overflow-x-auto py-2">
          <TablaMovimientos formatDate={formatDate} usuarioRuta={usuarioRuta} />
        </div>
      </div>
    </div>
  );
};
